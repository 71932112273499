@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
    background-image: url('/images/cont.png');
  background-color: #fff;
  text-align: center;
}


body,
input,
textarea,
button {
  font-family: "Inter", sans-serif;
}

.app {
    
  width: 0px;
  margin: 0 auto;
  height: 3000vh;
}

/* Contact.js */
.form {
   
  height: 70vh;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.form > h1 {
  margin-bottom: 0px;
}

.form > input,
textarea {
  padding: 10px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid lightgray;
  /* border: none; */
  background: rgb(196, 191, 191);
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
}

.form > input:focus,
textarea:focus {
  border: 1px solid rgb(196, 72, 0);
}

.form > textarea {
  height: 150px;
  max-width: 400px;
  min-height: 100px;
}

.form > label {
  padding-bottom: 0px;
  color: rgb(245, 245, 255);
  font-weight: bold;
}

.form > button {
  padding: 10px;
  border: none;
  background-color: rgb(129, 99, 0);
  font-weight: 500;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.form > button:hover {
  background-color: rgb(11, 39, 79);
  
}