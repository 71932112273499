@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
.navbar {
    
    /* background: linear-gradient(90deg, rgb(255, 255, 255) 100%, rgb(26, 23, 23) 0%); */
    background:#333 ;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 1500px;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    height: 80px;
  }
  
  .nav-links {
    color: #e9e8ed;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: 4px solid #e9e8ed;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #000;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #000;
      color: #242424;
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.services,
.products,
.contactus,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/1.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  
}

.contactus {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.signup {
  
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 100px;
  
}

.landingpage {
  
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 100px;
 
}

.payment {
  
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 100px;
 
}

:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--outline1 {
    background-color: #f5f2f2;
    color: rgb(12, 12, 12);
    padding: 8px 50px;
    border: 5px solid #fff;
    border: 5px solid var(--primary);
    transition: all 0.1s ease-out;
  }
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 8px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h2 {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
  }
  .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }

  .fa-envelope{

    margin-left: 4px;
    color: rgb(170, 73, 73)

  }
  
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
/* video {
    object-fit: cover;
    width: 100%;
    height: 50%;
    position: fixed;
    z-index: -1;
  } */
  
  .hero-container1 {
    background: url('/images/herosection2.png') center center/cover no-repeat;
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container1 > h1 {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  
  .hero-container1 > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container1 > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container1 > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container1 > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }
.footer-container {
  /* background-image: url('/images/cont.png'); */
    background: var(--darkGrey);
    background-color: #333;
    padding: 3rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #e9e8ed;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 20px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #000;
  }
  
  .footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
  }
  
  .footer-link-wrapper {
    display: flex;
  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #000;
  }
  
  .footer-link-items a {
    color: #e9e8ed;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input:-ms-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::-ms-input-placeholder {
    color: #b1b1b1;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #000;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .fa-angle-double-up{

    font-size: 2.5rem;
    width: 160px;
    color: #b1b1b1;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #e9e8ed;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  

  .website-rights {
    color: #e9e8ed;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }

  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: #C13584;
  }
  a.linkedin {
    color:#0e76a8 ;
  }
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
   
    
  }
  :root {
    --imageHost : '/images/1.png';
    --primaryColor: #af9a7d;
    --mainWhite: #fff;
    --offWhite: #f7f7f7;
    --mainBlack: #222;
    --mainGrey: #ececec;
    --darkGrey: #333;
    --mainTransition: all 0.3s linear;
    --mainSpacing: 3px;
    --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
    --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  }
  /* globals */
  /* body {
    padding-top: 66px;
    color: var(--mainBlack);
    background: var(--mainWhite);
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    line-height: 1.4;
  } */
  /* h1 {
    font-size: 3em;
    line-height: 1;
    margin-bottom: 0.5em;
  }
  h2 {
    font-size: 2em;
    margin-bottom: 0.75em;
  }
  h3 {
    font-size: 1.5em;
    line-height: 1;
    margin-bottom: 1em;
  }
  h4 {
    font-size: 1.2em;
    line-height: 1.25;
    margin-bottom: 1.25em;
  }
  h5 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  }
  h6 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1.5em;
  } */
  
  /* .btn-primary {
    display: inline-block;
    text-decoration: none;
    letter-spacing: var(--mainSpacing);
    color: var(--mainBlack);
    background: var(--primaryColor);
    padding: 0.4rem 0.9rem;
    border: 3px solid var(--primaryColor);
    transition: var(--mainTransition);
    text-transform: uppercase;
    cursor: pointer;
  }
  .btn-primary:hover {
    background: transparent;
    color: var(--primaryColor);
  }
  .loading {
    text-transform: capitalize;
    text-align: center;
    margin-top: 3rem;
  }
  .error {
    text-align: center;
    text-transform: uppercase;
    margin: 2rem 0;
  }
  .empty-search {
    text-align: center;
    text-transform: capitalize;
    margin: 2rem 0;
    padding: 1rem;
    letter-spacing: var(--mainSpacing);
  } */
  /* end of globals */
  /* Navbar */
  /* .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.75rem 2rem;
    background: var(--offWhite);
    z-index: 1;
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
  }
  .nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }
  .nav-icon {
    font-size: 1.5rem;
    color: var(--primaryColor);
  }
  .nav-links {
    height: 0;
    overflow: hidden;
    transition: var(--mainTransition);
    list-style-type: none;
  }
  .nav-links a {
    display: block;
    text-decoration: none;
    padding: 1rem 0;
    color: var(--mainBlack);
    transition: var(--mainTransition);
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: var(--mainSpacing);
  }
  .nav-links a:hover {
    color: var(--primaryColor);
  }
  
  .show-nav {
    height: 100px;
  }
  @media screen and (min-width: 768px) {
    .nav-btn {
      display: none;
    }
    .nav-center {
      max-width: 1170px;
      margin: 0 auto;
      display: flex;
    }
    .nav-links {
      height: auto;
      display: flex;
      margin-left: 4rem;
    }
    .nav-links a {
      margin: 0 1rem;
      padding: 0.5rem 0;
    }
  } */
  /* end of navbar */
  /* Hero */
  /* .defaultHero,
  .roomsHero {
    min-height: calc(100vh - 66px);
    background: url("./images/defaultBcg.jpeg") center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .roomsHero {
    background-image: url("./images/room-2.jpeg");
    min-height: 60vh;
  } */
  /* End of Hero */
  /* Banner */
  /* .banner {
    display: inline-block;
    background: rgba(0, 0, 0, 0.5);
    color: var(--mainWhite);
    padding: 2rem 1rem;
    text-align: center;
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .banner h1 {
    font-size: 2.5rem;
  }
  .banner div {
    width: 10rem;
    height: 5px;
    background: var(--primaryColor);
    margin: 1.7rem auto;
  }
  .banner p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 576px) {
    .banner {
      padding: 2rem 3rem;
    }
    .banner h1 {
      font-size: 3rem;
    }
  }
  @media screen and (min-width: 992px) {
    .banner {
      padding: 2rem 6rem;
    }
    .banner h1 {
      font-size: 4rem;
    }
  } */
  /* End of Banner */
  /* Title */
  .section-title {
    text-align: center;
    margin-bottom: 4rem;
  }
  .section-title h4 {
    font-size: 2rem;
    letter-spacing: 3px;
    letter-spacing: var(--mainSpacing);
    text-transform: capitalize;
    margin-bottom: 1rem;
  }
  .section-title div {
    
    width: 5rem;
    height: 5px;
    margin: 0 auto;
    background: #af9a7d;
    background: var(--primaryColor);
    
  }
  /* end of Title */
  
  /* business */
  .business {
    padding: 5rem 0;
    
  }
  .business {

    
    background: #333;

    
    background: var(--darkGrey);
    
  
  }
  .business-center {
    width: 90vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
    
  }
  .business span {
    display: inline-block;
    color: #af9a7d;
    color: var(--primaryColor);
    font-size: 4rem;
    margin-bottom: 1.5rem;
  }
 
  .business h6 {
    letter-spacing: 3px;
    letter-spacing: var(--mainSpacing);
    font-size: 1.5rem;
  }
  .business p {
    width: 80%;
    margin: 0 auto;
    font-size: 1.2rem;
    color: #f7f7f7;
    color: var(--offWhite);
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande','Lucida Sans', Arial, sans-serif; */
    /* font-family: 'Lucida Grande'; */
    
    
  }
  @media screen and (min-width: 992px) {
    .business-center {
      
      width: 95vw;
      max-width: 1170px;
    }
  }
  
  @media screen and (min-width: 1200px) {
    .business p {
      
      width: 100%;
    }
  }
  /*end of business */
  /* featured rooms */
  
  /* .featured-rooms {
    padding: 5rem 0;
  }
  .featured-rooms-center {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 40px;
  }
  @media screen and (min-width: 776px) {
    .featured-rooms-center {
      width: 90vw;
      grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    }
  }
  @media screen and (min-width: 992px) {
    .featured-rooms-center {
      width: 95vw;
      max-width: 1170px;
    }
  }
  /* end pf featured rooms */
  /* room */
  /* .room {
    box-shadow: var(--lightShadow);
    transition: var(--mainTransition);
  }
  .room:hover {
    box-shadow: var(--darkShadow);
  }
  
  .img-container {
    position: relative;
  }
  .img-container img {
    width: 100%;
    display: block;
    transition: var(--mainTransition);
  }
  .price-top {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    color: var(--mainWhite);
    padding: 0.3rem 0.6rem 0.5rem;
    border-bottom-right-radius: 1rem;
    font-size: 0.5rem;
    text-align: center;
    transition: var(--mainTransition);
  }
  .price-top h6 {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-weight: 300;
    letter-spacing: var(--mainSpacing);
  }
  .room-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0);
    transition: all 0.3s linear;
  }
  .img-container:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  .img-container:hover img {
    opacity: 0.3;
  }
  .img-container:hover .price-top {
    opacity: 0;
  }
  .img-container:hover .room-link {
    transform: translate(-50%, -50%) scale(1);
  }
  .room-info {
    background: var(--darkGrey);
    text-transform: capitalize;
    padding: 0.5rem 0;
    text-align: center;
    font-weight: 700;
    letter-spacing: var(--mainSpacing);
  } */ 
  /* end of room  */
  /* single room*/
/*   
  .single-room {
    padding: 5rem 0 0 0;
  }
  .single-room-images {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 50px;
  }
  .single-room-images img {
    width: 100%;
    display: block;
  }
  .single-room-info {
    width: 80vw;
    display: grid;
    grid-template-columns: 1fr;
    margin: 2rem auto;
  }
  .desc,
  .info {
    margin: 1rem 0;
  }
  .desc h3 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .desc p {
    line-height: 1.5;
  }
  .info h3,
  .info h6 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  
  .info h6 {
    font-weight: 300;
  }
  .room-extras {
    width: 80vw;
    margin: 0 auto 3rem auto;
  }
  .room-extras h6 {
    text-transform: capitalize;
    letter-spacing: var(--mainSpacing);
  }
  .extras {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
  }
  @media screen and (min-width: 992px) {
    .single-room-images,
    .single-room-info,
    .room-extras {
      width: 95vw;
      max-width: 1170px;
    }
    .single-room-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }
    .info {
      padding-left: 3rem;
    }
  } */
  /* end of single room*/
  /* roomlist */
  /* .roomslist {
    padding: 5rem 0;
  }
  .roomslist-center {
    width: 80vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 30px;
  }
  
  @media screen and (min-width: 776px) {
    .roomslist-center {
      width: 90vw;
    }
  }
  @media screen and (min-width: 992px) {
    .roomslist-center {
      width: 95vw;
      max-width: 1170px;
    }
  } */
  /* end of roomlist */
  /*  rooms fitler*/
  /* .filter-container {
    padding: 5rem 0;
  }
  .filter-form {
    width: 60vw;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(202px, 1fr));
    grid-row-gap: 2rem;
    grid-column-gap: 40px;
  }
  .form-group {
    text-transform: capitalize;
  }
  .form-group label {
    display: block;
    letter-spacing: var(--mainSpacing);
    margin-bottom: 0.5rem;
  }
  .form-control {
    width: 100%;
    background: transparent;
    font-size: 1rem;
  }
  .size-inputs {
    display: flex;
  }
  .size-input {
    width: 40%;
    padding: 0.2rem;
    border: 1px solid var(--mainBlack);
    border-radius: 0.3rem;
    margin-right: 0.3rem;
  }
  .single-extra label {
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }
  @media screen and (min-width: 776px) {
    .filter-form {
      width: 70vw;
    }
  }
  @media screen and (min-width: 992px) {
    .filter-form {
      width: 95vw;
      max-width: 1170px;
    }
  } */
  /* end of rooms fitler*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
    background-image: url('/images/cont.png');
  background-color: #fff;
  text-align: center;
}


body,
input,
textarea,
button {
  font-family: "Inter", sans-serif;
}

.app {
    
  width: 0px;
  margin: 0 auto;
  height: 3000vh;
}

/* Contact.js */
.form {
   
  height: 70vh;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.form > h1 {
  margin-bottom: 0px;
}

.form > input,
textarea {
  padding: 10px;
  border-radius: 3px;
  /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.137); */
  margin-bottom: 20px;
  border: 1px solid lightgray;
  /* border: none; */
  background: rgb(196, 191, 191);
  font-size: 16px;
  color: rgb(0, 0, 32);
  outline: none;
}

.form > input:focus,
textarea:focus {
  border: 1px solid rgb(196, 72, 0);
}

.form > textarea {
  height: 150px;
  max-width: 400px;
  min-height: 100px;
}

.form > label {
  padding-bottom: 0px;
  color: rgb(245, 245, 255);
  font-weight: bold;
}

.form > button {
  padding: 10px;
  border: none;
  background-color: rgb(129, 99, 0);
  font-weight: 500;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.form > button:hover {
  background-color: rgb(11, 39, 79);
  
}
/* video {
    object-fit: cover;
    width: 100%;
    height: 50%;
    position: fixed;
    z-index: -1;
  } */
  
  .landing-container1 {
    /* background: url('/images/herosection2.png') center center/cover no-repeat; */
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .landing-container1 > h1 {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  
  .landing-container1 > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  
  @media screen and (max-width: 960px) {
    .landing-container1 > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .landing-container1 > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .landing-container1 > p {
      font-size: 30px;
    }
  
    
  }
/* video {
    object-fit: cover;
    width: 100%;
    height: 50%;
    position: fixed;
    z-index: -1;
  } */
  
  .landing-container2 {
    /* background: url('/images/herosection2.png') center center/cover no-repeat; */
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .landing-container2 > h1 {
    color: #fff;
    font-size: 80px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  .landing-container2 > h3 {
    color: #fff;
    font-size: 20px;
    margin-top: -100px;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
  }
  
  .landing-container2 > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  
  @media screen and (max-width: 960px) {
    .landing-container2 > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .landing-container2 > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .landing-container2 > p {
      font-size: 30px;
    }
  
    
  }
